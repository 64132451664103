import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import Alert from '@material-ui/lab/Alert';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';

import UserDataService from '../../services/UserService';
import LocationDataService from '../../services/LocationService';
import DepartmentDataService from '../../services/DepartmentService';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: (props) => ({
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1),
        },
        '& .MuiAlert-root': {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(1),
            },
        },
    }),
}));

const AddUserForm = () => {
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        retrieveLocations();
    }, []);

    const retrieveLocations = () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        LocationDataService.getAll()
            .then((response) => {
                let filteredLocations;

                if (locData && locData.id === 'all') {
                    // Show all locations
                    filteredLocations = response.data;
                } else if (Array.isArray(locData)) {
                    // Filter locations based on the locData array
                    filteredLocations = response.data.filter((location) =>
                        locData.includes(location.id)
                    );
                } else {
                    // If locData is invalid or not present, show no locations
                    filteredLocations = [];
                }

                setLocations(filteredLocations);
                console.log(filteredLocations);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const [departments, setDepartments] = useState([]);

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    useEffect(() => {
        retrieveDepartments();
    }, []);

    const retrieveDepartments = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await DepartmentDataService.getAll(joinedValues);
            } else if (locData.id === 'all') {
                response = await DepartmentDataService.adminAll();
            }

            if (response) {
                setDepartments(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const initialUserState = {
        id: null,
        // sap: '',
        name: '',
        loc_number: null,
        status: 3,
        loc_name: '',
        sub_loc_name: '',
        pin: null,
        email: null,
        phone: null,
        address: null,
        card: null,
        staff: false,
        guest_id: null,
    };

    const [user, setUser] = useState(initialUserState);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Assuming 'locations' is available in this scope or passed as a prop
        if (name === 'loc_name') {
            const selectedLocation = locations.find(
                (location) => location.name === value
            );
            if (selectedLocation) {
                setUser((prevState) => ({
                    ...prevState,
                    loc_name: value, // Update loc_name as before
                    loc_number: selectedLocation.number, // Additionally, update loc_number
                }));
            }
        } else {
            // Handle other inputs generically
            setUser((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    const saveUser = () => {
        setIsLoading(true); // Start loading

        var data = {
            name: user.name,
            loc_number: user.loc_number,
            loc_name: user.loc_name,
            sub_loc_name: user.sub_loc_name,
            status: user.status,
            sap: user.sap,
            pin: user.pin,
            email: user.email,
            phone: user.phone,
            address: user.address,
            card: user.card,
            staff: user.staff,
            guest_id: user.guest_id,
        };

        UserDataService.create(data)
            .then((response) => {
                setUser({
                    id: response.data.id,
                    name: response.data.name,
                    loc_number: response.data.loc_number,
                    loc_name: response.data.loc_name,
                    sub_loc_name: response.data.sub_loc_name,
                    status: response.data.status,
                    sap: response.data.sap,
                    pin: response.data.pin,
                    email: response.data.email,
                    phone: response.data.phone,
                    card: response.data.card,
                    address: response.data.address,
                    staff: response.data.staff,
                    guest_id: response.data.guest_id,
                });
                setSubmitted(true);
                setIsLoading(false);
                console.log(response.data);
                setTimeout(function () {
                    window.location.reload(1);
                }, 2000);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const newUser = () => {
        setUser(initialUserState);
        setSubmitted(false);
    };

    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(
        new Date('2014-08-18T21:11:54')
    );

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const classes = useStyles();

    const currentHost = window.location.hostname;
    const currentPort = window.location.port;
    const isBilla = currentHost === 'billa.biodit.bg';
    const isDemo = currentHost === 'attendance-demo.biodit.info';
    const isBricolage = currentHost === 'bricolage.biodit.com';
    const isSwissport = currentHost === 'swissport.biodit.bg';
    const isLocalhost = currentHost === 'localhost' && currentPort === '4000';
    const isLocalhostBilla =
        currentHost === 'localhost' && currentPort === '4001';

    return (
        <div className="submit-form">
            {submitted ? (
                // <Alert onClose={() => { }}>Успешно добавен!</Alert>
                <div>
                    <Alert>Успешно добавихте служителя!</Alert>
                    {/* <h4>You submitted successfully!</h4>
                    <button className="btn btn-success" onClick={newUser}>
                        Add
                    </button> */}
                </div>
            ) : (
                <ValidationGroup>
                    <form className={classes.root}>
                        <div>
                            <Validate
                                name="name"
                                required={[true, 'Това поле е задължително!']}
                            >
                                <TextField
                                    required
                                    id="outlined-required name"
                                    label="Име"
                                    variant="outlined"
                                    name="name"
                                    value={user.name}
                                    onChange={handleInputChange}
                                    size="small"
                                />
                            </Validate>
                            <Validate
                                name="loc_name"
                                required={[true, 'Това поле е задължително!']}
                            >
                                <TextField
                                    id="outlined-select-location loc_name"
                                    select
                                    required
                                    label="Изберете Локация"
                                    name="loc_name"
                                    value={user.loc_name}
                                    onChange={handleInputChange}
                                    size="small"
                                    variant="outlined"
                                >
                                    {locations.map((location) => (
                                        <MenuItem
                                            key={location.id}
                                            value={location.name}
                                        >
                                            {location.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Validate>
                        </div>
                        <div>
                            <Validate
                                name="sub_loc_name"
                                required={[true, 'Това поле е задължително!']}
                            >
                                <TextField
                                    id="outlined-select-sub-location sub_loc_name"
                                    select
                                    required
                                    name="sub_loc_name"
                                    label="Изберете Отдел"
                                    value={user.sub_loc_name}
                                    onChange={handleInputChange}
                                    size="small"
                                    variant="outlined"
                                >
                                    <MenuItem></MenuItem>
                                    {departments.map((department) => (
                                        <MenuItem
                                            key={department.id}
                                            value={department.name}
                                        >
                                            {department.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Validate>
                            <Validate name="pin">
                                <TextField
                                    id="outlined-required pin"
                                    label="Пин"
                                    variant="outlined"
                                    name="pin"
                                    value={user.pin}
                                    onChange={handleInputChange}
                                    size="small"
                                />
                            </Validate>
                        </div>
                        {/* <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Край"
                                        format="MM/dd/yyyy"
                                        name="start_date"
                                        id="start_date"
                                        value={selectedDate === user.start_date}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        size="small"
                                    />
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Край"
                                        format="MM/dd/yyyy"
                                        name="start_date"
                                        id="start_date"
                                        value={user.end_start_date}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        size="small"
                                    />
                                </MuiPickersUtilsProvider>
                            </div> */}
                        {/*<div>*/}
                        <div>
                            {isSwissport && (
                                <Validate name="guest_id">
                                    <TextField
                                        id="outlined-required guest_id"
                                        label="ЕГН"
                                        variant="outlined"
                                        name="guest_id"
                                        value={user.guest_id}
                                        onChange={handleInputChange}
                                        size="small"
                                    />
                                </Validate>
                            )}

                            <Validate name="card">
                                <TextField
                                    id="outlined-required card"
                                    label="Карта"
                                    variant="outlined"
                                    name="card"
                                    value={user.card}
                                    onChange={handleInputChange}
                                    size="small"
                                />
                            </Validate>
                        </div>

                        {/*    <Validate name="phone">*/}
                        {/*        <TextField*/}
                        {/*            id="outlined-required phone"*/}
                        {/*            label="Телефон"*/}
                        {/*            variant="outlined"*/}
                        {/*            name="phone"*/}
                        {/*            value={user.phone}*/}
                        {/*            onChange={handleInputChange}*/}
                        {/*            size="small"*/}
                        {/*        />*/}
                        {/*    </Validate>*/}
                        {/*</div>*/}
                        <AutoDisabler>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveUser}
                                disabled={isLoading} // Disable button when loading
                            >
                                {isLoading ? 'Добавяме...' : 'Добави'}
                            </Button>
                        </AutoDisabler>
                    </form>
                </ValidationGroup>
            )}
        </div>
    );
};

export default AddUserForm;
